import { useSdk } from './use-sdk';

const SERVICE_NAME = 'personal-details';

export function usePersonalDetails () {
  const sdk = useSdk();

  async function listDirectoryItems (opts) {
    const page = opts?.page || 1;
    const limit = opts?.limit || 10;
    const skip = (page - 1) * limit;

    const query = {
      status: 'published',
    };

    query.$limit = limit;

    if (skip > 0) {
      query.$skip = skip;
    }

    if (opts?.searchQuery) {
      query.$search = opts.searchQuery;
    }

    if (opts?.specialties) {
      query['person.doc_specialties'] = {
        $in: opts.specialties,
      };
    }

    if (opts?.uid) {
      query['person.id'] = {
        $ne: opts.uid,
      }
    }
    
    return sdk.list(SERVICE_NAME, query);
  }

  async function updateItem (id, data) {
    return sdk.update(SERVICE_NAME, id, data);
  }

  async function getItem (id) {
    const query = {
      $or: [
        {
          id,
        },
        {
          doc_website: id,
        },
      ],
    };
    return sdk.get(SERVICE_NAME, query);
  }

  return {
    updateItem,
    getItem,
    listDirectoryItems,
  };
}
